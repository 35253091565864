import React, { useEffect, useState } from "react"
import { inject, observer } from "mobx-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import DeliveryForm from "../components/delivery-form"
import { Link } from "gatsby"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { PRODUCT } from "../stores/cart-store"
import Box from "@material-ui/core/Box"
import ImageGallery from "react-image-gallery"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import { IMAGE_GALLERY } from "../lib/helpers/states"
import Rating from "@material-ui/lab/Rating"
import firebase from "firebase/app"
import Modal from "../components/modal"
import { useQueryParam } from "gatsby-query-params"

const firebaseConfig = {
  apiKey: "AIzaSyC4yYUJumZOgMSgVZY5esFF8-gi807pI6g",
  authDomain: "lock-pole-defender.firebaseapp.com",
  projectId: "lock-pole-defender",
}

const PageLayout = styled.div`
  display: grid;
  font-family: Helvetica;
  width: 100%;
  height: auto;
  grid-gap: 16px;
  grid-template-areas:
    "bread-crumbs bread-crumbs bread-crumbs bread-crumbs bread-crumbs"
    "prod-detail prod-detail prod-detail img-container img-container"
    "prod-detail prod-detail prod-detail img-container img-container"
    "prod-detail prod-detail prod-detail img-container img-container";
  margin-top: 95px;
  grid-template-columns: repeat(5, 1fr);

  @media only screen and (max-width: 860px) {
    grid-template-areas:
      "bread-crumbs"
      "img-container"
      "prod-detail";

    grid-template-columns: 1fr;
    grid-gap: 0px;
  }

  .alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`

const BreadcrumbsContainer = styled.div`
  grid-area: bread-crumbs;
  a {
    color: #0084ff;
  }
`

const ProductImageContainer = styled.div`
  grid-area: img-container;
  width: 100%;
`

const ImgageStickyContainer = styled.div`
  position: sticky;
  top: 96px;

  img {
    margin-bottom: 0;
  }

  .image-gallery-svg {
    height: 54px;
    width: 33px;
  }

  .image-gallery-thumbnail {
    height: auto;
    margin-bottom: 30px;
    transition: none;
  }

  .image-gallery-thumbnail: hover {
    border: 2px solid #337ab7;
    box-size: border-box;
  }

  .image-gallery-thumbnail.active {
    border: 2px solid #337ab7;
  }
`
const Color = styled.div`
  color: #374048;
  text-transform: uppercase;
  font: 700 28px/1 "Poppins", sans-serif;
  margin-bottom: 16px;
`

const ProductDetailContainer = styled.div`
  grid-area: prod-detail;
  width: 100%;
`
const Description = styled.div`
  width: 100%;
  font-size: 16px;
  font: 400 16px/28px "Poppins", sans-serif;
  color: #979a9d;
  a {
    color: #0084ff;
    text-decoration: underline;
  }
`

const FormContainer = styled.div`
  padding: 0 16px;

  @media only screen and (max-width: 860px) {
    padding: 16px 0;
  }
`

const Title = styled.h1`
  width: 100%;
  color: #848e96;
  margin-bottom: 10px;
  text-transform: uppercase;
  font: 500 18px/1 "Poppins", sans-serif;
`
const Label = styled.div`
  color: #6c6e71;
  text-transform: uppercase;
  margin: ${props => (props.margin ? props.margin : " 16px 0 16px 0")};
  font: 600 16px/1 "Poppins", sans-serif;
`

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

let firebaseSubmitSampleRequest

const LPDSamplePage = ({ cart }) => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            id
            fluid(maxWidth: 2000, maxHeight: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const source = useQueryParam("source", "")

  useEffect(() => {
    import("firebase").then(module => {
      const { default: firebase } = module

      if (firebase) {
        if (!firebase.apps.length) {
          firebase.initializeApp(firebaseConfig)
        }

        if (process.env.NODE_ENV === "development") {
          firebase.functions().useEmulator("localhost", 5001)
        }
        firebaseSubmitSampleRequest = firebase
          .functions()
          .httpsCallable("submitSampleRequest")
      }
    })
  }, [])

  const handleFormSubmission = async () => {
    const shippingInfo = cart.shippingInfo
    const contactInfo = cart.contactInfo

    if (cart.isSectionOneValid) {
      setIsSubmitting(true)
      const response = await firebaseSubmitSampleRequest({
        source,
        ...contactInfo,
        ...shippingInfo,
      })
      const { error, success } = response.data

      if (success) {
        setSuccessMessage(
          "We have received your request. We will be in touch shortly."
        )
        if (window && window.lintrk) {
          window.lintrk("track", { conversion_id: 15117148 })
        }
      } else if (error) {
        setErrorMessage("There was an error submitting your request.")
      }

      setIsSubmitting(false)
    } else {
      cart.setDirtyForm()
    }
  }

  const imageGalleryHashTable = IMAGE_GALLERY.reduce((acc, img) => {
    const table = acc
    return {
      ...table,
      [img.original]: true,
    }
  }, {})

  const imageGallery = data.allImageSharp.edges
    .map(({ node }) => {
      const imageUrlArray = node.fluid.src.split("/")
      const imgUrl = imageUrlArray.slice(imageUrlArray.length - 1)

      return {
        original: node.fluid.src,
        key: imgUrl,
      }
    })
    .filter(image => {
      return imageGalleryHashTable[image.key]
    })

  return (
    <Layout>
      <SEO title="Request sample for locking handle hole cover for light poles" />
      <PageLayout>
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">Home</Link>
            <div>Request sample</div>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        <ProductImageContainer>
          <ImgageStickyContainer>
            <ImageGallery
              items={imageGallery}
              showPlayButton={false}
              showFullscreenButton={false}
              showThumbnails={false}
              showBullets={true}
              showIndex={true}
            />
          </ImgageStickyContainer>
        </ProductImageContainer>
        <ProductDetailContainer>
          <FormContainer>
            <Color>Sample Request</Color>
            <Title>
              {PRODUCT.name}
              <span>
                <small>
                  {`  `} {PRODUCT.subTitle}
                </small>
              </span>
            </Title>

            <Rating name="half-rating-read" value={5} precision={0.5} />

            <Description>
              <Label>Description</Label>

              {PRODUCT.description}
              <span>
                <small>{PRODUCT.subTitle}</small>
              </span>
              <br />
              <a href="/lpd-cutsheet.pdf" download="/lpd-cutsheet.pdf">
                Download Cutsheet
              </a>
            </Description>
            <Label>
              {" "}
              Request a sample <small>(Fill out form)</small>
            </Label>
            <Box mt="16px">
              <DeliveryForm
                buttonText="Submit Request"
                handleShippingSubmission={handleFormSubmission}
                isSubmitting={isSubmitting}
              />
            </Box>
          </FormContainer>
        </ProductDetailContainer>
      </PageLayout>
      {errorMessage && (
        <Modal
          type="error"
          title="Error"
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}
      {successMessage && (
        <Modal
          type="success"
          title="Success"
          message="We have received your request. We will be in touch shortly."
          onClose={() => navigate("/")}
        />
      )}
      {isSubmitting && (
        <Modal
          type="success"
          title="Submitting"
          message="We are processing your sample request. Please wait."
        />
      )}
    </Layout>
  )
}

export default inject("cart")(observer(LPDSamplePage))
